import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout/layoutWithContainer.js";
import SEO from "../components/seo";
import Certifications from "../components/certifications-page/certifications/certifications";
import { Link } from "gatsby";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Certifications achieved by Hatton Enterprise Solutions' Consultants" mdxType="SEO" />
    <div className="showcaseSection"> <div className="prose"> <h1>Certifications</h1> <p>Our consultants have achieved the following certifications:</p> </div> <hr /> <Certifications mdxType="Certifications" /> <p>Would you like to work with us?</p> <p>Get in 
  <a href="https://forms.zohopublic.com/hattonesolutions/form/ProductEnquiry/formperma/kQ2hHUkTTkVBJCIMbv8s5wWU8B5FvF_TEcEdCcqoAqc" target="_blank" rel="noopener noreferrer">touch</a>.</p> <br /><br /> 
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      